import React from 'react';
import { Layout, Formik, Button } from '@base';
import * as Yup from 'yup';
import jwt from 'jsonwebtoken';
import axios from 'axios';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(6, '*Name has to be longer than 6 characters!')
    .required('*Name is required!'),
  email: Yup.string()
    .email('*email is not valid!')
    .required('*email is required!'),
  message: Yup.string()
    .min(10, '*message has to be longer than 10 characters!')
    .required('*message is required!'),
});

const initialValues = {
  name: '',
  email: '',
  message: '',
};

const encode = (data: any) => Object.keys(data)
  .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

const handleSubmit = (values: any) => {
  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({ 'form-name': 'contact', ...values }),
  })
    .then(() => console.log('Form submitted!'))
    .catch(error => console.log(error));

  values.to = values.email;
  values.from = 'hello@progressive.is';
  values.subject = values.name;
  values.body = values.message;

  const formValues = jwt.sign(values, '3F19cAcB85f8E06CD0ab4c9d17E782EdE7D77C022A4dF5b0688d526Dd6C9eF87');
  axios.post('https://nbbvdzljcj.execute-api.us-east-1.amazonaws.com/prod/mail', {payload: formValues});
};

const renderForm = ({ handleSubmit }: any) => (
  <Formik.Container name="contact" data-netlify data-netlify-honeypot="robot-honeypot">
    <Formik.FieldGroup name="name" label="Name" />
    <Formik.FieldGroup name="email" label="Email" type="email" />
    <Formik.FieldGroup name="message" label="Message" type="textarea" />
    <Formik.FieldGroup name="robot-honeypot" label="Please leave empty" hidden />
    <Button onClick={handleSubmit}>Submit</Button>
  </Formik.Container>
);

export default () => (
  <Layout>
    <h1>Look at this fancy form!</h1>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={renderForm}
    />
  </Layout>
);
